import { PaymentWidgetInstance, loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import { postOrder } from 'apis/order';
import { BottomButton } from 'features/layout/LayoutContext';
import useLayout from 'features/layout/useLayout';
import PaymentContents from 'features/payment/PaymentContents';
import { Place } from 'features/payment/types';
import { formatPrice } from 'features/payment/utils';
import React, { useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import useCartStore from 'store/useCartStore';

function PaymentPage(): JSX.Element {
  const { initializeBottomButtons, setBottomButtons } = useLayout();
  const { shopId, itemId } = useParams();
  const { paymentWidget } = useLoaderData() as { paymentWidget: PaymentWidgetInstance | undefined };
  const { cart } = useCartStore();
  const [place, setPlace] = useState<Place>('TO_GO');
  const toPayOrderList = itemId ? cart.filter(cartItem => cartItem.orderId === itemId) : cart;
  const totalPrice = toPayOrderList.reduce((acc, cur) => (acc += cur.priceSum * cur.quantity), 0);

  useEffect(() => {
    const payButton: BottomButton = {
      text: `${formatPrice(totalPrice)} 결제하기`,
      onClick: async () => {
        if (shopId === undefined) return;

        try {
          const order = await postOrder(shopId, {
            orders: cart.map(order => ({
              ingredientIds: order.ingredients.map(ingredient => ingredient.ingredient.id),
              packageType: place,
              temperatureType: order.temperatureType ? ('ICE' as const) : ('HOT' as const),
              sizeType: order.sizeType,
            }))[0],
          });
          await paymentWidget?.requestPayment({
            orderId: order.orderId,
            orderName: '...님의 주문',
            successUrl: `${window.location.origin}/${shopId}/payment/success`,
            failUrl: `${window.location.origin}/${shopId}/payment/fail`,
          });
        } catch (error) {
          console.error(error);
        }
      },
    };

    setBottomButtons([payButton, null]);

    return () => initializeBottomButtons();
  }, [place]);

  return <PaymentContents place={place} onChangePlace={place => setPlace(place)} />;
}

export const loader = async () => {
  try {
    const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY ?? '';
    const customerKey = crypto.randomUUID();
    const paymentWidget = await loadPaymentWidget(clientKey, customerKey);

    return { paymentWidget };
  } catch (err) {
    console.error(err);
  }
};

export default PaymentPage;
