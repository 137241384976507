import { Button, Flex, Title } from '@mantine/core';
import { useState } from 'react';
import { SizeType, TemperatureType } from 'types';

interface OrderPopupProps {
  onClose: (type: 'CART' | 'PAY', temperature: TemperatureType, size: SizeType) => void;
}

const MenuPopup = ({ onClose }: OrderPopupProps) => {
  const [temperature, setTemperature] = useState<TemperatureType | null>(null);
  const [size, setSize] = useState<SizeType | null>(null);
  const TEMPERATURE: { [key: string]: TemperatureType } = {
    Hot: 'HOT',
    Ice: 'ICE',
  } as const;
  const SIZE: { [key: string]: SizeType } = {
    Small: 'SMALL',
    Medium: 'MEDIUM',
    Large: 'LARGE',
    'Extra Large': 'EXTRA_LARGE',
  } as const;

  function selectTemperature(option: TemperatureType) {
    if (temperature === option) {
      setTemperature(null);
    } else {
      setTemperature(option);
    }
  }

  function selectSize(option: SizeType) {
    if (size === option) {
      setSize(null);
    } else {
      setSize(option);
    }
  }

  const isReady = temperature && size;

  return (
    <Flex direction="column" mt={15} gap={20}>
      <Title order={2}>음료 제작 옵션</Title>
      <Flex direction="column" gap={8}>
        <Title order={3}>Hot/Iced</Title>
        <Flex gap={8} wrap="wrap">
          {Object.keys(TEMPERATURE).map(option => (
            <Button
              variant={temperature === TEMPERATURE[option] ? 'filled' : 'light'}
              size="md"
              radius="md"
              key={option}
              onClick={() => selectTemperature(TEMPERATURE[option])}>
              {option}
            </Button>
          ))}
        </Flex>
      </Flex>
      <Flex direction="column" gap={8}>
        <Title order={3}>Size</Title>
        <Flex gap={8} wrap="wrap">
          {Object.keys(SIZE).map(option => (
            <Button
              variant={size === SIZE[option] ? 'filled' : 'light'}
              size="md"
              radius="md"
              key={option}
              onClick={() => selectSize(SIZE[option])}>
              {option}
            </Button>
          ))}
        </Flex>
      </Flex>
      {/* <Flex direction="column" gap={8}>
        <Flex direction="row" gap={8} align="flex-end">
          <Title order={3}>Water</Title>
          <Text>(100%는 사이즈에 맞게 채워드립니다)</Text>
        </Flex>
        <Flex gap="16px">
          {['No', '적게', '가득'].map((option, i) => (
            <Button variant="filled" color="#E8E8E8" key={i}>
              <Text size="16px" c="black">
                {option}
              </Text>
            </Button>
          ))}
        </Flex>
      </Flex>
      <Flex direction="column" gap={8}>
        <Title order={3}>Ice</Title>
        <Flex gap="16px">
          {['No', '적게', '보통'].map((option, i) => (
            <Button variant="filled" color="#E8E8E8" key={i}>
              <Text size="16px" c="black">
                {option}
              </Text>
            </Button>
          ))}
        </Flex>
      </Flex> */}
      <Flex mt={24} gap={16}>
        <Button
          size="lg"
          radius="md"
          fullWidth
          variant="outline"
          disabled={!isReady}
          onClick={() => {
            onClose('CART', temperature!, size!);
          }}>
          장바구니 담기
        </Button>
        <Button size="lg" radius="md" fullWidth disabled={!isReady} onClick={() => onClose('PAY', temperature!, size!)}>
          바로 주문하기
        </Button>
      </Flex>
    </Flex>
  );
};

export default MenuPopup;
