import { Grid, SimpleGrid, Text, Title } from '@mantine/core';
import ToPayOrderList from './ToPayOrderList';
import OrderPlace from './OrderPlace';
import PaymentWidget from './PaymentWidget';
import { Place } from './types';
import useCartStore from 'store/useCartStore';
import { useParams } from 'react-router-dom';

interface Props {
  place: Place;
  onChangePlace: (place: Place) => void;
}

function PaymentContents({ place, onChangePlace }: Props) {
  const { itemId } = useParams();
  const { cart, selectedShop, getSortedCart } = useCartStore();
  const toPayOrderList = itemId ? cart.filter(cartItem => cartItem.orderId === itemId) : getSortedCart();
  const totalPrice = toPayOrderList.reduce((acc, cur) => (acc += cur.priceSum * cur.quantity), 0);

  return (
    <SimpleGrid cols={1} spacing={0}>
      <Grid justify="space-between" align="center" classNames={{ root: 'pb-[16px]' }}>
        <Grid.Col span="content">
          <Title order={3}>주문 매장</Title>
        </Grid.Col>
        <Grid.Col span="content">
          <Text size="md">{selectedShop?.name}</Text>
        </Grid.Col>
      </Grid>
      <OrderPlace place={place} onChangePlace={onChangePlace} />
      <SimpleGrid cols={1} classNames={{ root: 'py-[16px]' }}>
        <header>
          <Title order={3}>주문 레시피</Title>
        </header>
        <ToPayOrderList orderList={toPayOrderList} />
      </SimpleGrid>
      <SimpleGrid>
        <PaymentWidget price={totalPrice} />
      </SimpleGrid>
    </SimpleGrid>
  );
}

export default PaymentContents;
