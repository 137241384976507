import { Box, Button, Drawer, Flex, Text, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import { useGetIngredient, useGetShop } from 'apis/queries/shop';
import IngredientPopup from '../component/IngredientPopup';
import MenuPopup from '../component/MenuPopup';
import { useNavigate, useParams } from 'react-router-dom';
import useOrderStore from 'store/useOrderStore';
import { useDisclosure } from '@mantine/hooks';
import MenuIngredientItem from '../component/MenuIngredientItem';
import { CartOrderItem, SizeType, TemperatureType } from 'types';
import useCartStore from 'store/useCartStore';
import { nanoid } from 'nanoid';
import AskGoCartPopup from '../component/AskGoCartPopup';
import { ROUTE_PATHS, getFullPathById } from 'routes';

export interface IngredientContentsProps {
  orderPopupOpened: boolean;
  closeOrderPopup(): void;
}

const IngredientContents = ({ orderPopupOpened, closeOrderPopup }: IngredientContentsProps): JSX.Element => {
  const { setIngredientInfo, setSelectedInfo, selectedInfo, ingredientInfo } = useOrderStore();
  const { shopId } = useParams();
  const { data: ingredientList } = useGetIngredient(Number(shopId));
  const { data: shop } = useGetShop(Number(shopId));
  const [opened, { open, close }] = useDisclosure();
  const disclosureAskGoCart = useDisclosure();
  const totalPrice = Object.entries(selectedInfo).reduce((acc, cur) => {
    const id = cur[0];
    return acc + ingredientInfo[id].price * cur[1];
  }, 0);

  useEffect(() => {
    setIngredientInfo(ingredientList?.data || []);
  }, [ingredientList]);

  const { cart, addCart, selectedShop, setSelectedShop } = useCartStore();
  const navigate = useNavigate();

  function finishOrder(type: 'CART' | 'PAY', temperature: TemperatureType, size: SizeType) {
    cart.forEach(cartItem => {
      if (cartItem.shopId !== selectedShop?.id) {
        // 이 가게 담을건지 물어보기
      }
    });

    const newCartItemId = nanoid();
    const newCartItem: CartOrderItem = {
      orderId: newCartItemId,
      sizeType: size,
      temperatureType: temperature,
      priceSum: totalPrice,
      ingredients: Object.entries(selectedInfo).map(([id, count]) => {
        return { ingredient: ingredientInfo[id], quantity: count };
      }),
      shopId: Number(shopId),
      quantity: 1,
      addedTime: new Date(),
    };
    addCart(newCartItem);
    setSelectedShop(shop!.data);
    closeOrderPopup();

    if (type === 'CART') {
      disclosureAskGoCart[1].open();
    } else {
      setSelectedInfo([]);
      navigate(getFullPathById(ROUTE_PATHS.PAYMENT_ONE_ITEM.id, { shopId: shopId, itemId: newCartItemId }));
    }
  }

  function closeAskGoCart() {
    disclosureAskGoCart[1].close();
    setSelectedInfo([]);
  }

  return (
    <Box>
      <Flex direction="column" justify="space-between" mih="82vh" gap={28} pb={20}>
        <Box p="0 0 16px 0">
          <Flex direction="column" gap="16px">
            <Title order={3}>주문 - {shop?.data.name}</Title>
            {Object.keys(selectedInfo).length !== 0 ? (
              <Flex direction="column" gap="16px">
                {Object.entries(selectedInfo).map(([id, count]) => (
                  <MenuIngredientItem key={id} ingredient={ingredientInfo[id]} count={count} />
                ))}
              </Flex>
            ) : (
              <div
                style={{
                  height: '5vh',
                  width: '90vw',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'gray',
                }}>
                <Text size="lg">레시피에 재료를 추가해보세요!</Text>
              </div>
            )}
            <Button fullWidth size="lg" radius="md" variant="light" onClick={() => open()}>
              +
            </Button>
          </Flex>
        </Box>
        <Flex direction="column" align="flex-end">
          <Title order={2}>{`${totalPrice.toLocaleString('ko-KR')} 원`}</Title>
        </Flex>
      </Flex>
      <Drawer
        position="bottom"
        size="lg"
        opened={opened}
        onClose={close}
        withCloseButton={false}
        style={{ overflow: 'hidden' }}>
        <IngredientPopup onClose={close} />
      </Drawer>
      <Drawer
        position="bottom"
        size="md"
        opened={orderPopupOpened}
        onClose={closeOrderPopup}
        withCloseButton={false}
        style={{ overflow: 'hidden' }}>
        <MenuPopup onClose={finishOrder} />
      </Drawer>
      <Drawer
        position="bottom"
        size="25vh"
        opened={disclosureAskGoCart[0]}
        onClose={closeAskGoCart}
        withCloseButton={false}
        style={{ overflow: 'hidden' }}>
        <AskGoCartPopup onClose={closeAskGoCart} />
      </Drawer>
    </Box>
  );
};

export default IngredientContents;
