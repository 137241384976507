import { CartOrderItem, ShopItem } from 'types';
import { create } from 'zustand';

interface State {
  cart: CartOrderItem[];
  selectedShop: ShopItem | null;
}

interface Handler {
  addCart: (orderItem: CartOrderItem) => void;
  removeCart: (id: string) => void;
  modifyCart: (orderItem: CartOrderItem) => void;
  setSelectedShop: (shop: ShopItem | null) => void;
  getSortedCart: () => CartOrderItem[];
}

export type StateType = State & Handler;

const LUWAK_CART_KEY = 'luwak-cart';
const LUWAK_SELECTED_SHOP_KEY = 'luwak-selected-shop';

const initialState: State = {
  cart: JSON.parse(localStorage.getItem(LUWAK_CART_KEY) || 'false') || [],
  selectedShop: JSON.parse(localStorage.getItem(LUWAK_SELECTED_SHOP_KEY) || 'false') || null,
};

const useCartStore = create<StateType>((set, get) => ({
  ...initialState,
  addCart: (orderItem: CartOrderItem) => {
    set(state => {
      state.cart = [orderItem, ...state.cart];
      localStorage.setItem(LUWAK_CART_KEY, JSON.stringify(state.cart));
      return state;
    });
  },
  removeCart: (id: string) => {
    set(state => {
      const tempCart = [...state.cart.filter(orderItem => orderItem.orderId !== id)];
      const tempState = { ...state, cart: tempCart };
      localStorage.setItem(LUWAK_CART_KEY, JSON.stringify(tempCart));
      if (tempCart.length === 0) {
        localStorage.removeItem(LUWAK_SELECTED_SHOP_KEY);
        return { ...tempState, selectedShop: null };
      }
      return tempState;
    });
  },
  modifyCart: (orderItem: CartOrderItem) => {
    set(state => {
      const tempCart = [...state.cart.filter(cartItem => cartItem.orderId !== orderItem.orderId), orderItem];
      const tempState = { ...state, cart: tempCart };
      localStorage.setItem(LUWAK_CART_KEY, JSON.stringify(tempCart));
      return tempState;
    });
  },
  setSelectedShop: (shop: ShopItem | null) => {
    set(state => {
      state.selectedShop = shop;
      localStorage.setItem(LUWAK_SELECTED_SHOP_KEY, JSON.stringify(state.selectedShop));
      return state;
    });
  },
  getSortedCart: () => {
    const sortedCart = get().cart.sort(cartItemSort);
    return [...sortedCart];
  },
}));

function cartItemSort(a: CartOrderItem, b: CartOrderItem) {
  if (a.addedTime < b.addedTime) {
    return 1;
  } else if (a.addedTime > b.addedTime) {
    return -1;
  } else {
    return 0;
  }
}

export default useCartStore;
